import React, { useMemo } from 'react';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  CheckCircle2,
  ProcessingLine,
  WrongCircle,
  Info,
} from '@combateafraude/icons/general';

import ValidationIssueYearUF from './components/ValidationIssueYearUF';
import ReproveDocument from './components/ReproveDocument';
import Observation from './components/Observation';
import AvaliationInfo from './components/AvaliationInfo';

import '../styles.less';

const { Panel } = Collapse;

const UFYearIsEmpty = ({
  recordData,
  isReview,
  setUfYearChanged,
  ufYearChanged,
  reproveChanged,
  setReproveChanged,
  setAvaliationFinished,
  redirectUrl,
}) => {
  const { hash } = useLocation();

  const {
    avaliationInfo,
    documentscopy = null,
    relatedRecordId = null,
    queue = null,
    digitalManipulationCheck = null,
  } = recordData?.record;

  const defaultValuesCollapse = useMemo(() => {
    const values = ['validation-year-uf', 'reprove'];
    if (
      (documentscopy?.obs && queue?.solutionLevel !== 'quality') ||
      (relatedRecordId && queue?.solutionLevel !== 'quality') ||
      queue?.solutionLevel === 'poc' ||
      queue?.solutionLevel === 'qrcode' ||
      digitalManipulationCheck?.result
    ) {
      values.push('obs');
    }

    if (isReview) {
      values.push('avaliation-info');
    }

    return values;
  }, [isReview, documentscopy, relatedRecordId, queue, digitalManipulationCheck]);

  return (
    <Collapse className="mrg-left-10" defaultActiveKey={defaultValuesCollapse} ghost>
      {isReview && (
        <Panel
          header="Dados referente a avaliação"
          key="avaliation-info"
          className={`${classNames({
            'primary-border': avaliationInfo?.evaluated && documentscopy?.approved,
            'danger-border': avaliationInfo?.evaluated && !documentscopy?.approved,
            'success-border': avaliationInfo?.evaluated && documentscopy?.reprocess,
          })} mrg-btm-20`}
          extra={
            // eslint-disable-next-line no-nested-ternary
            avaliationInfo?.evaluated && documentscopy?.approved !== null ? (
              documentscopy?.approved ? (
                <CheckCircle2 className="gx-text-primary" width={24} height={24} />
              ) : (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              )
            ) : (
              documentscopy?.reprocess && (
                <ProcessingLine width={24} height={24} className="gx-text-success" />
              )
            )
          }
        >
          <AvaliationInfo recordData={recordData} />
        </Panel>
      )}

      <Panel
        header="Observações sobre o documento"
        key="obs"
        className={`${classNames({
          'warning-border': defaultValuesCollapse.includes('obs'),
        })} mrg-btm-20`}
        extra={
          defaultValuesCollapse.includes('obs') && (
            <Info width={24} height={24} className="gx-text-warning" />
          )
        }
      >
        <Observation recordData={recordData} />
      </Panel>
      <Panel
        header="Validação de Ano e UF de emissão"
        key="validation-year-uf"
        className="mrg-btm-20 danger-border"
        extra={<WrongCircle className="gx-text-danger" width={24} height={24} />}
      >
        <ValidationIssueYearUF
          recordData={recordData}
          isReview={isReview}
          ufYearChanged={ufYearChanged}
          setUfYearChanged={setUfYearChanged}
          setAvaliationFinished={setAvaliationFinished}
          redirectUrl={redirectUrl}
        />
      </Panel>

      {!isReview && (
        <Panel
          id="reprove-component"
          header="Reprovar o documento"
          key="reprove"
          className={`${classNames({
            'danger-border': hash === '#reprove-component',
          })} mrg-btm-20`}
        >
          <ReproveDocument
            recordData={recordData}
            isReview={isReview}
            reproveChanged={reproveChanged}
            setReproveChanged={setReproveChanged}
            redirectUrl={redirectUrl}
          />
        </Panel>
      )}
    </Collapse>
  );
};

UFYearIsEmpty.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
  isReview: PropTypes.bool,
  setUfYearChanged: PropTypes.func,
  ufYearChanged: PropTypes.bool,
  setReproveChanged: PropTypes.func,
  reproveChanged: PropTypes.bool,
  setAvaliationFinished: PropTypes.func,
  redirectUrl: PropTypes.string,
};

UFYearIsEmpty.defaultProps = {
  isReview: false,
  ufYearChanged: false,
  reproveChanged: false,
  redirectUrl: '/manual-review-wizard',
  setUfYearChanged: () => {},
  setReproveChanged: () => {},
  setAvaliationFinished: () => {},
};

export default UFYearIsEmpty;
