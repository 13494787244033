import React, { useMemo, useCallback } from 'react';
import { Typography, Button, Divider, Popover } from 'antd';

import {
  CheckCircle2,
  WrongCircle,
  ProcessingLine,
  CheckD,
  Eye,
  AlertTriangle,
  Criminal,
  Image,
  Calendar,
  Tag,
  ShieldAlert,
} from '@combateafraude/icons/general';
import { Person } from '@combateafraude/icons/users';
import { StrictEvaluation } from '@combateafraude/icons/face';
import PropTypes from 'prop-types';

import { hasRequiredPermissions } from 'routes/permissionFunctions';
import { toFormatDate } from 'utils/formatters';
import { useAuth } from 'hooks/auth';

const { Text } = Typography;

const AvaliationInfo = ({ recordData }) => {
  const { loggedUser } = useAuth();

  const { record = null } = recordData;
  const { reportSuspect = null } = record?.executionInfo?.postEvaluationReview || {};
  const { incorrectAnalysis = null } = record?.postEvaluationReview || {};

  const avaliationData =
    record?.documentscopy || record?.pendingOcr || record?.doubleCheckLiveness;

  const reproveReasons = useMemo(() => {
    if (
      avaliationData?.disapprovalReasons?.disapprovalMessage ||
      (avaliationData?.disapprovalReasons &&
        avaliationData?.disapprovalReasons?.length > 0)
    ) {
      return (
        <>
          {avaliationData?.disapprovalReasons?.disapprovalMessage ? (
            <div className="card-info">
              <span className="mrg-btm-5">
                {avaliationData?.disapprovalReasons?.disapprovalMessage}
                <br />
              </span>
            </div>
          ) : (
            avaliationData?.disapprovalReasons?.map((reason) => (
              <div className="card-info">
                <br />
                <span className="mrg-btm-5">· {reason.disapprovalMessage}</span>
              </div>
            ))
          )}
        </>
      );
    }
    return <></>;
  }, [avaliationData]);

  const openInBackoffice = useCallback(() => {
    let backofficeUrlTail = '';

    if (
      (record?.originalTenantId || record?.executionInfo?.tenantId) &&
      record?.executionInfo?.executionId
    ) {
      backofficeUrlTail = `executions/details/user/${
        record.originalTenantId ? record.originalTenantId : record.executionInfo.tenantId
      }/report/000000000000000000000000/execution/${record.executionInfo.executionId}`;
    }
    const backofficeUrl = `${process.env.REACT_APP_BASE_URL_BACKOFFICE_WEB}/${backofficeUrlTail}`;
    window.open(backofficeUrl, '_blank');
  }, [record]);

  const renderPostEvaluationReviewDetails = (postEvalReview) => (
    <div className="flex fd-column center-start" style={{ gap: '10px' }}>
      {(postEvalReview?.type === 'front' || postEvalReview?.type === 'selfie') && (
        <div className="card-info flex center">
          <Image width={24} height={24} />
          <span className="mrg-left-5">{`Imagem suspeita: ${
            postEvalReview?.type === 'front' ? 'Frente do documento' : 'Selfie'
          }`}</span>
        </div>
      )}
      {(postEvalReview?.type === 'falsePositive' ||
        postEvalReview?.type === 'falseNegative') && (
        <div className="card-info flex center">
          <Tag width={24} height={24} />
          <span className="mrg-left-5">
            {postEvalReview?.type === 'falseNegative'
              ? 'Falso negativo'
              : 'Falso positivo'}
          </span>
        </div>
      )}
      <div className="card-info flex center">
        <Person width={24} height={24} />
        <span className="mrg-left-5">{`Por: ${postEvalReview?.author}`}</span>
      </div>
      <div className="card-info flex center">
        <Calendar width={24} height={24} />
        <span className="mrg-left-5">{`Em: ${toFormatDate(postEvalReview?.date)}`}</span>
      </div>
    </div>
  );

  return (
    <>
      <Text />
      <div className="card-body">
        <div className="flex-1 flex-column center-start">
          <div className="flex gx-w-100">
            <p className="card-title max-lines-2 gx-w-100 text-bold">
              Observações gerais sobre a avaliação.
            </p>
          </div>

          {record?.type === 'double-check-liveness' && <Divider />}

          {avaliationData && typeof avaliationData?.approved === 'boolean' && (
            <div
              className={`card-info ${
                avaliationData?.approved ? 'approved' : 'reproved'
              }`}
            >
              {avaliationData?.approved ? (
                <CheckCircle2 className="gx-text-primary" width={24} height={24} />
              ) : (
                <WrongCircle className="gx-text-danger" width={24} height={24} />
              )}
              <span className="mrg-left-5">
                {avaliationData?.approved ? 'Aprovado' : 'Reprovado'}
              </span>
            </div>
          )}
        </div>
      </div>
      {avaliationData &&
        typeof avaliationData?.doubleCheck === 'boolean' &&
        avaliationData.doubleCheck && (
          <div className="card-info mrg-top-15">
            <CheckD width={24} height={24} />
            <span className="mrg-left-5">Double-check</span>
          </div>
        )}
      {avaliationData &&
        typeof avaliationData?.reprocess === 'boolean' &&
        avaliationData?.reprocess && (
          <div className="card-info">
            <ProcessingLine width={24} height={24} className="gx-text-success" />
            <span className="mrg-left-5">Reprocessado</span>
          </div>
        )}

      <div className="card-info">
        <Eye width={24} height={24} />
        <span className="mrg-left-5">
          {recordData?.record?.avaliationInfo?.evaluated
            ? `Avaliado por: ${recordData?.record?.avaliationInfo?.user?.name}`
            : 'Aguardando avaliação'}
        </span>
      </div>

      {record?.doubleCheckLiveness &&
        record?.settings?.strictEvaluation &&
        typeof record?.settings?.strictEvaluation === 'boolean' && (
          <div className="card-info mrg-top-10">
            <StrictEvaluation width={24} height={24} />
            <span className="mrg-left-5">Avaliação rígida</span>
          </div>
        )}

      {record?.documentscopy &&
        record?.settings?.flexibleEvaluation &&
        typeof record?.settings?.flexibleEvaluation === 'boolean' && (
          <div className="card-info">
            <ShieldAlert width={24} height={24} />
            <span className="mrg-left-5">Avaliação flexível</span>
          </div>
        )}

      {record?.documentscopy && record?.queue?.solutionLevel === 'quality' && (
        <div className="card-info mrg-top-10">
          <StrictEvaluation width={24} height={24} />
          <span className="mrg-left-5">Avaliação de qualidade</span>
        </div>
      )}

      {avaliationData &&
        typeof avaliationData?.approved === 'boolean' &&
        !avaliationData.approved && (
          <div className="card-info">
            <AlertTriangle width={24} height={24} />
            <span className="mrg-left-5">
              Motivo da reprovação:{' '}
              {
                // eslint-disable-next-line no-nested-ternary
                avaliationData.fraud
                  ? 'Fraude'
                  : avaliationData.unreadable
                  ? 'Ilegibilidade'
                  : 'Geral'
              }
            </span>
          </div>
        )}
      <div>{reproveReasons}</div>
      {record?.avaliationInfo && record?.avaliationInfo?.evaluated && (
        <>
          {hasRequiredPermissions(loggedUser, ['wecheck_reportSuspect:read'], []) &&
            reportSuspect?.isTrue && (
              <Popover
                content={() => renderPostEvaluationReviewDetails(reportSuspect)}
                trigger="hover"
              >
                <p className="card-info">
                  <Criminal width={24} height={24} />
                  <span className="mrg-left-5">Reportado como suspeito</span>
                </p>
              </Popover>
            )}

          {hasRequiredPermissions(loggedUser, ['wecheck_incorrectAnalysis:read'], []) &&
            incorrectAnalysis?.isTrue && (
              <Popover
                content={() => renderPostEvaluationReviewDetails(incorrectAnalysis)}
                trigger="hover"
              >
                <p className="card-info">
                  <WrongCircle width={24} height={24} />
                  <span className="mrg-left-5">Análise marcada como incorreta</span>
                </p>
              </Popover>
            )}
        </>
      )}
      <Button type="link" className="btn-link" onClick={openInBackoffice}>
        Abrir execução no Backoffice
      </Button>
    </>
  );
};
AvaliationInfo.propTypes = {
  recordData: PropTypes.objectOf(PropTypes.objectOf).isRequired,
};

export default AvaliationInfo;
